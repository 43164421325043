<template>
  <div class="form home-search">
    <input
      id="homepage-search"
      ref="homepageSearchInputBox"
      v-model="query"
      v-click-outside="clickOutsideSearch"
      placeholder="Search by region, variety, or winery"
      type="search"
      :class="{ dirty: query !== '' }"
      @blur="setSearchFocused(false)"
      @focus="setSearchFocused(true)"
      @keyup.enter="goToSearch()"
    />
    <label for="homepage-search" class="sr-only"
      >Search by region, variety, or winery</label
    >
    <button v-if="!query" class="button primary">
      <span class="sr-only">Search by region, variety, or winery</span>
    </button>
    <button v-else class="button clear" @click="hideSearch">
      <span class="sr-only">Clear Search</span>
    </button>
    <button v-if="mobileSearchOpen" class="close" @click="closeMobileSearch()">
      Close
    </button>
    <div
      class="search-results"
      :class="{
        open: searchResultsOpen,
        'search-txt-unfocused': !searchHasFocus,
        'search-txt-focused': searchHasFocus,
      }"
      role="alert"
    >
      <div
        v-if="homepageSearchCollections.length > 0"
        role="region"
        aria-describedby="search-collections"
      >
        <h2 id="search-collections">Collections</h2>
        <ul class="collections">
          <li
            v-for="collection in homepageSearchCollections.slice(0, 3)"
            :key="collection.slug"
            :data-product-id="collection.slug"
          >
            <nuxt-link :to="collection.url" @click.native="hideSearch()">
              <span class="name">{{ collection.display_name }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div
        v-if="homepageSearchWineClubs.length > 0"
        role="region"
        aria-describedby="search-wine-clubs"
      >
        <h2 id="search-wine-clubs">Wine Clubs</h2>
        <ul class="collections">
          <li
            v-for="wineclub in homepageSearchWineClubs"
            :key="wineclub.slug"
            :data-product-id="wineclub.slug"
          >
            <nuxt-link :to="wineclub.url" @click.native="hideSearch()">
              <span class="name">{{ wineclub.display_name }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div
        v-if="homepageSearchLinks.length > 0"
        role="region"
        aria-describedby="search-links"
      >
        <h2 id="search-links">Pages</h2>
        <ul class="collections">
          <li
            v-for="link in homepageSearchLinks.slice(0, 5)"
            :key="link.slug"
            :data-product-id="link.slug"
          >
            <nuxt-link :to="link.url" @click.native="hideSearch()">
              <span class="name">{{ link.display_name }}</span>
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div
        v-if="query.length > 2"
        role="region"
        aria-describedby="search-product"
      >
        <h2 id="search-product">Product</h2>
        <ul v-if="homepageSearchResults.length > 0" class="products">
          <li
            v-for="product in homepageSearchResults.slice(0, 5)"
            :key="product.id"
            :data-product-id="product.product_id"
          >
            <nuxt-link :to="product.url" @click.native="hideSearch()">
              <span class="image">
                <picture>
                  <source
                    v-lazy-load :data-srcset="product.bottle_image_optimized_url"
                    type="image/webp"
                  />
                  <source
                    v-lazy-load :data-srcset="product.bottle_image_url"
                    type="image/jpeg"
                  />
                  <img
                    v-lazy-load :data-src="product.bottle_image_url"
                    alt="Wine Bottle"
                    height="220"
                  />
                </picture>
              </span>
              <span class="name">{{ product.title }}</span>
              <span class="winery">{{ product.producing_winery }}</span>
              <span class="price">${{ product.price_excl_tax }}</span>
            </nuxt-link>
          </li>
        </ul>
        <ul v-else class="products">
          <li v-if="homepageSearchExecuting">Searching...</li>
          <li v-else>No Results</li>
        </ul>
      </div>
      <div v-else class="message">
        Type 3 or more characters to search
      </div>
      <nuxt-link
        v-if="query.length > 2 && homepageSearchCount > 0"
        class="view-all"
        :to="getStoreSearchUrl()"
        @click.native="hideSearch()"
        >View all {{ homepageSearchCount }} results</nuxt-link
      >
      <nuxt-link
        v-else
        class="view-all"
        :to="`/store/`"
        @click.native="hideSearch()"
        >Shop All Wines</nuxt-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { debounce } from '~/components/utils'
import { pushEvent } from '~/../common/utils/user'
import { getHeaderHeight } from '~/../common/components/utils'
export default {
  name: 'HomepageSearchBar',
  data() {
    return {
      searchHasFocus: false,
      timeoutHandle: null,
      query: '',
      searchResultsPosition: 130,
      windowWidth: null,
    }
  },
  computed: {
    ...mapGetters([
      'anySearchExecuting',
      'getHomepageStoreSearchUrl',
      'homepageSearchCollections',
      'homepageSearchWineClubs',
      'homepageSearchLinks',
      'homepageSearchCount',
      'homepageSearchResults',
      'homepageSearchExecuting',
      'homepageSearchOpen',
      'mobileSearchOpen',
    ]),
    searchResultsOpen() {
      return (
        this.mobileSearchOpen ||
        (this.homepageSearchOpen && this.query.length > 0)
      )
    },
  },
  watch: {
    query(newValue, oldValue) {
      this.executeSearch(newValue)
    },
    showPromoBanner(newValue, oldValue) {
      this.adjustSearchResultsPosition()
    },
    searchHasFocus(newValue) {
      if (newValue) {
        this.openSearch()
      }
    },
    homepageSearchExecuting(newValue, oldValue) {
      if (!newValue && oldValue) {
        if (this.query.length > 2) {
          // Track the search event via Segment
          pushEvent(
            this.$axios,
            {
              metric_name: 'Global Navigation Search Executed',
              url_path: this.$route.fullPath,
              extra_data: {
                search_location: 'homepage',
                search_string: this.query,
                store_count: this.count,
                lto_count: this.ltoCount,
              },
            },
            this.$store
          )
        }
      }
    },
  },
  created() {
    this.executeSearch = debounce(this.executeSearch, 500)
  },
  mounted() {
    this.adjustSearchResultsPosition()
    // Listen for resize events
    window.addEventListener('resize', this.adjustSearchResultsPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustSearchResultsPosition)
  },
  methods: {
    executeSearch(query) {
      this.$store.dispatch('executeHomepageSearch', query)
    },
    adjustSearchResultsPosition() {
      if (process.client) {
        this.$nextTick(() => {
          this.windowWidth = window.innerWidth
          const height = getHeaderHeight()
          this.searchResultsPosition = height + 'px'
        })
      }
    },
    openSearch() {
      this.$refs.homepageSearchInputBox.blur()
      if (this.windowWidth && this.windowWidth <= 1024) {
        this.$store.dispatch('setMobileSearchOpen', true)
        this.$nextTick(() => {
          this.$root.$emit('focusSearchBox')
          // eslint-disable-next-line no-undef
          $('html, body').animate({ scrollTop: 0 })
        })
      } else {
        this.$store.dispatch('setHomepageSearchOpen', true)
        this.adjustSearchResultsPosition()
        this.$nextTick(() => {
          this.$refs.homepageSearchInputBox.focus()
        })
      }
    },
    closeMobileSearch() {
      this.$store.dispatch('setMobileSearchOpen', false)
    },
    hideSearch() {
      this.closeMobileSearch()
      this.$emit('closed')
      this.query = ''
      this.$store.dispatch('clearHomepageSearch')
    },
    setSearchFocused(v) {
      if (!this.timeoutHandle) {
        this.timeoutHandle = setTimeout(() => {
          this.searchHasFocus = v
          this.timeoutHandle = null
        }, 200)
      }
    },
    goToSearch() {
      if (this.executeSearch.isPending() || this.anySearchExecuting) {
        this.$refs.homepageSearchInputBox.blur()
        this.$router.push(this.getStoreSearchUrl(false))
        this.hideSearch()
      }
      const storeSearchUrl = this.getStoreSearchUrl()
      if (storeSearchUrl === '/store/') {
        return
      }
      this.$refs.homepageSearchInputBox.blur()
      this.$router.push(storeSearchUrl)
      this.hideSearch()
    },
    getStoreSearchUrl(validateResults = true) {
      return this.getHomepageStoreSearchUrl(this.query, validateResults)
    },
    clickOutsideSearch() {
      this.$store.dispatch('setHomepageSearchOpen', false)
    },
  },
}
</script>
